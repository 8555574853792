import React, { FC, MouseEventHandler, useMemo } from "react";
import classnames from "classnames";
import LogoutIcon from "@mui/icons-material/Logout";
import { Avatar } from "../Header/Avatar";
import { Menu, MenuItem } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
export interface LoginButtonProps {
  onLogin: MouseEventHandler<HTMLButtonElement>;
  onLogout: MouseEventHandler<HTMLElement>;
  onAccountManagementRequest: MouseEventHandler<HTMLElement>;
  profile?: Record<string, unknown>;
  loading: boolean;
}

export const LoginButton: FC<LoginButtonProps> = ({
  onLogin,
  onLogout,
  onAccountManagementRequest,
  profile,
  loading,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(500));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const name = useMemo(() => {
    if (!profile?.name) {
      return;
    }
    const parts = (profile.name as string).split(" ");
    if (parts.length > 1) {
      return `${parts[0]} ${parts[parts.length - 1]}`;
    }

    return profile.name;
  }, [profile?.name]);

  const content = (
    <div
      className={classnames({
        "xvia-header-btn": true,
        "xvia-header-btn__loading": !profile && loading,
        "xvia-header-btn__inactive": !!profile,
      })}
    >
      {!profile ? (
        <button onClick={loading ? undefined : onLogin}>
          {!isSmallScreen && <LogoutIcon />}
          Acessar
        </button>
      ) : (
        <>
          <div
            onClick={handleMenuOpen}
            className="xvia-header-user-avatar"
            title={String(name)}
          >
            <Avatar name={String(name)} />
          </div>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              key="user-account"
              sx={{ color: "#000000DE" }}
              onClick={(e) => onAccountManagementRequest(e)}
            >
              Meus dados
            </MenuItem>
            <MenuItem
              key="logout"
              sx={{ color: "#000000DE" }}
              onClick={(e) => onLogout(e)}
            >
              Sair
            </MenuItem>
          </Menu>
        </>
      )}
    </div>
  );

  return content;
};
