import React, { FC, useCallback, useEffect, useState } from "react";
import {
  LoginButton,
  LoginButtonProps,
} from "../components/LoginButton/LoginButton";
import { SearchBar } from "../components/Header/SearchBar/SearchBar";
import { useKeycloak } from "@react-keycloak/ssr";
import { KeycloakInstance } from "keycloak-js";
import { usePortalAuth } from "../providers/keycloak-provider";
import { usePortalProvider } from "../providers/portal-provider";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export const HeaderActionsContainer: FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(500));
  const { keycloak, initialized } = useKeycloak<KeycloakInstance>();
  const { login, logout } = usePortalAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const { ssr, profile, setProfile, sendMessageToApp } = usePortalProvider();

  useEffect(() => {
    setLoading(true);
    if (!initialized) {
      return;
    }

    setLoading(false);

    if (keycloak?.authenticated) {
      setLoading(true);
      keycloak?.loadUserInfo().then((profile) => {
        setProfile(profile);
        setLoading(false);
      });
    }
  }, [keycloak, initialized, setProfile]);

  const onLogin = useCallback(() => {
    if (ssr) {
      login();
      return;
    }
    keycloak?.login();
  }, [login, keycloak, ssr]);

  const onLogout = useCallback(() => {
    if (ssr) {
      logout();
      return;
    }

    keycloak?.logout();
  }, [logout, keycloak, ssr]);

  const onAccountManagementRequest = useCallback(
    () => keycloak?.accountManagement(),
    [keycloak]
  );

  const onClickSearch = useCallback(() => {
    if (sendMessageToApp) {
      sendMessageToApp({
        detail: {
          pushRoute: `/search/${value}`,
        },
      });
    }
  }, [value, sendMessageToApp]);

  const onChangeText = useCallback((text: string) => {
    setValue(text);
  }, []);

  const props: LoginButtonProps = {
    onLogin,
    onLogout,
    onAccountManagementRequest,
    profile,
    loading,
  };

  return (
    <>
      <SearchBar
        className="xvia-home-search__input"
        placeholder="Digite o que procura..."
        onClickSearch={onClickSearch}
        onChangeText={onChangeText}
        value={value}
        aria-label="Campo de busca de serviços"
      />
      {!isSmallScreen && <span className="xvia-home-search__separator">|</span>}
      <LoginButton {...props} />
    </>
  );
};
