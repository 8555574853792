import { Avatar as MUIAvatar } from "@mui/material";
import Stack from "@mui/material/Stack";

interface AvatarProps {
  name: string;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: "#F8F8F8",
      color: "#000",
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export const Avatar = ({ name }: AvatarProps) => {
  return (
    <Stack direction="row" spacing={2}>
      <MUIAvatar variant="rounded" {...stringAvatar(name)} />
    </Stack>
  );
};
